// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Custom Components
import RadiusServerApi from '@src/services/RadiusServerApi'

//const https = require('https');

// ** App Config
//import appConfig from '@configs/appConfig'


export const isRadiusServerRunning = createAsyncThunk('appRadius/up', async params => {
  const response = await axios(RadiusServerApi.generateAxiosParams('get', '/', params))
  return {
    params,
    data: response.data
  }
})

export const getNas = createAsyncThunk('appRadius/getNas', async params => {
  const response = await axios(RadiusServerApi.generateAxiosParams('get', '/nas', params))
  return {
    params,
    data: response.data
  }
})

export const getUsers = createAsyncThunk('appRadius/getUsers', async params => {
  const response = await axios(RadiusServerApi.generateAxiosParams('get', '/users', params))
  return {
    params,
    data: response.data
  }
})

/* export const getData = createAsyncThunk('appLicense/getData', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/auth/keys', params))
  //const response = await axios.get(licensesUrl + '/auth/keys', _params)
  //console.log(response.data)
  let d = [...response.data]
  d.map(el => {
    el.handleEditLabel = params.handleEditLabel
    el.clientId = params.clientId
  })
  return {
    params,
    data: d,
    allData: d,
    totalPages: 1 //response.data.total
  }
})

export const createNewLicense = createAsyncThunk('appLicense/createNew', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('post', '/auth/key', params))
  return {
    params,
    data: response.data
  }
})

export const expireLicense = createAsyncThunk('appLicense/expire', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('delete', '/auth/key', params))
  return {
    params,
    data: response.data
  }
})

export const enableLicense = createAsyncThunk('appLicense/enable', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('put', '/auth/key', params))
  return {
    params,
    data: response.data
  }
})

export const editLabel = createAsyncThunk('appLicense/editLabel', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('post', '/auth/key/label', params))
  return {
    params,
    data: response.data
  }
}) */

export const appRadiusNasSlice = createSlice({
  name: 'appRadiusNas',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getNas.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export const appRadiusUsersSlice = createSlice({
  name: 'appRadiusUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export const radiusNas = appRadiusNasSlice.reducer
export const radiusUsers = appRadiusUsersSlice.reducer
