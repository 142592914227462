// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const spinnerSlice = createSlice({
  name: 'spinner',
  initialState: {
    visible: false,
    message: null,
  },
  reducers: {
    showSpinner: (state, action) => {
      state.visible = true
      state.message = action?.payload?.message
    },
    hideSpinner: (state) => {
      state.visible = false
      state.message = null
    },
    setSpinnerMessage: (state, action) => {
      state.message = action?.payload?.message
    },
  }
})

export const { showSpinner, hideSpinner } = spinnerSlice.actions

export default spinnerSlice.reducer
