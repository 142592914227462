// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Custom Components
import LicensingServerApi from '@src/services/LicensingServerApi'

export const isLicensingServerRunning = createAsyncThunk('appUser/up', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/', params))
  return {
    params,
    data: response.data
  }
})

/*export const getClients = createAsyncThunk('appLicense/getClients', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/auth/clients', params))
  return {
    params,
    data: response.data
  }
})*/

export const getData = createAsyncThunk('appUser/getData', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/auth/users', params))
  let d = [...response.data]
  d.map(el => {
    el.handleResetPasswd = params.handleResetPasswd
    //el.clientId = params.clientId
  })
  return {
    params,
    data: d,
    allData: d,
    totalPages: 1 //response.data.total
  }
})

/*export const createNewLicense = createAsyncThunk('appLicense/createNew', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('post', '/auth/key', params))
  return {
    params,
    data: response.data
  }
})
*/

export const disableUser = createAsyncThunk('appUser/disable', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('delete', '/auth/user', params))
  return {
    params,
    data: response.data
  }
})

export const enableUser = createAsyncThunk('appUser/enable', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('put', '/auth/user', params))
  return {
    params,
    data: response.data
  }
})

export const resetPasswd = createAsyncThunk('appUser/resetPasswd', async params => {
  let p = {email: params.email};
  let payload = {passwd: params.passwd};
  const response = await axios(LicensingServerApi.generateAxiosParams('post', '/auth/user/passwd', p, payload))
  return {
    params,
    data: response.data
  }
})

/*
export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  return id
})
*/

export const appUserSlice = createSlice({
  name: 'appUser',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export default appUserSlice.reducer
