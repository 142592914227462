// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const apiSlice = createSlice({
  name: 'api',
  initialState: {
    securities: null,
    forex: null,
    commodities: null,
    cryptos: null,
  },
  reducers: {
    handleSecurities: (state, action) => {
      state.securities = action.payload
      localStorage.setItem('securities', JSON.stringify(action.payload))
    },
    handleForex: (state, action) => {
      state.forex = action.payload
      localStorage.setItem('forex', JSON.stringify(action.payload))
    },
  }
})

export const { handleSecurities, handleForex } = apiSlice.actions

export default apiSlice.reducer
