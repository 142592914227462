// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Custom Components
import LicensingServerApi from '@src/services/LicensingServerApi'

//const https = require('https');

// ** App Config
//import appConfig from '@configs/appConfig'


export const isLicensingServerRunning = createAsyncThunk('appProject/up', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/', params))
  return {
    params,
    data: response.data
  }
})

export const getClients = createAsyncThunk('appProject/getClients', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/clients', params))
  return {
    params,
    data: response.data
  }
})

export const getData = createAsyncThunk('appProject/getData', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/projects', params))
  let d = [...response.data]
  d.map(el => {
    el.handleEditName = params.handleEditName
    el.clientId = params.clientId
  })
  return {
    params,
    data: d,
    allData: d,
    totalPages: 1 //response.data.total
  }
})

export const createNewProject = createAsyncThunk('appProject/createNew', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('put', '/project', params))
  return {
    params,
    data: response.data
  }
})

/* export const expireLicense = createAsyncThunk('appLicense/expire', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('delete', '/auth/key', params))
  return {
    params,
    data: response.data
  }
})

export const enableLicense = createAsyncThunk('appLicense/enable', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('put', '/auth/key', params))
  return {
    params,
    data: response.data
  }
})*/

export const editName = createAsyncThunk('appProject/editName', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('post', '/project/name', params))
  return {
    params,
    data: response.data
  }
}) 

/*
export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  return id
})
*/

export const appProjectSlice = createSlice({
  name: 'appProject',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export default appProjectSlice.reducer
