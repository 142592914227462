const https = require('https');

// ** App Config
import appConfig from '@configs/appConfig'

const radiusUrl = appConfig.radiusUrl[appConfig.radiusUrl.env];

const agent = new https.Agent({
    rejectUnauthorized: false,
    requestCert: false,
    agent: false,
});

const RadiusServerApi = {
    generateAxiosParams: function(method, urlPath, params, payload) {
        let p = {
            method,
            url:  radiusUrl + urlPath,
            //httpsAgent: agent,
            params: {},
            headers: {},
            timeout: 2000,
            data: payload,
        };
        if (appConfig.iobAdminKey) {
            p.headers['NBS-ADMIN-KEY'] = appConfig.iobAdminKey
        }
        if (params?.clientId) {
            p.params['clientId'] = params.clientId
        }
        if (params?.apiKey) {
            p.params['apiKey'] = params.apiKey
        }
        if (params?.username) {
            p.params['username'] = params.username
        }
        if (params?.passwd) {
            p.params['passwd'] = params.passwd
        }
        return p
    },
};
export default RadiusServerApi;

