// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import api from './api'
import spinner from './spinner'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import licenses from '@src/views/apps/nbs/licenses/store'
import projects from '@src/views/apps/nbs/projects/store'
import {imuList, imuLogs, imuEvents} from '@src/views/apps/nbs/imu/store'
import {radiusNas, radiusUsers} from '@src/views/apps/nbs/radius/store'
import user from '@src/views/apps/nbs/user/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

const rootReducer = {
  auth,
  api,
  spinner,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  licenses,
  projects,
  imuList,
  imuLogs,
  imuEvents,
  radiusNas,
  radiusUsers,
  user,
  calendar,
  ecommerce,
  dataTables,
  permissions
}

export default rootReducer
