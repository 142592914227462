const https = require('https')

// ** Utils
import SecurityUtils from '@src/services/SecurityUtils'
import {getUserData} from '@utils'

// ** App Config
import appConfig from '@configs/appConfig'

const ImuMonitorUrlApi = appConfig.ImuMonitorUrl[appConfig.ImuMonitorUrl.env] + "/api/mon/v1"
const ImuMonitorUrlApiWeb = appConfig.ImuMonitorUrl[appConfig.ImuMonitorUrl.env] + "/api/web/v1"

const agent = new https.Agent({
    rejectUnauthorized: false,
    requestCert: false,
    agent: false,
});

const MonitorImuServerApi = {
    getServerBaseUrl: function(urlPath) {
        if (urlPath === '/config') {
            return ImuMonitorUrlApi
        }
        return ImuMonitorUrlApiWeb
    },
    generateRootAxiosParams: function(method, urlPath, params, payload) {
        let p = this.generateAxiosParams(method, urlPath, params, payload)
        let position = p.url.search(/\/api/i);
        let rootPath = p.url.substring(0, position) + urlPath
        p.url = rootPath
        return p
    },
    generateAxiosParams: function(method, urlPath, params, payload) {
        let session = getUserData()

        let p = {
            method,
            url:  MonitorImuServerApi.getServerBaseUrl(urlPath) + urlPath,
            httpsAgent: agent,
            params: {},
            headers: {},
            timeout: 4000,
            data: payload,
        }

        if (session) {
            p.headers['NBS-SESSION-TOKEN'] = session?.session_key
        }

        const csrftoken = SecurityUtils.getCsrf()
        if (csrftoken) {
            p.headers['NBS-CSRF-TOKEN'] = csrftoken
            SecurityUtils.updateCsrf()
        }

        if (appConfig.iobAdminKey) {
            p.headers['NBS-ADMIN-KEY'] = appConfig.iobAdminKey
        }
        if (params?.clientId) {
            p.params['clientId'] = params.clientId
        }
        if (params?.projectId) {
            p.params['projectId'] = params.projectId
        }
        if (params?.apiKey) {
            p.params['apiKey'] = params.apiKey
        }
        if (params?.mac) {
            p.params['mac'] = params.mac
        }
        if (params?.label) {
            p.params['label'] = params.label
        }
        if (params?.id) {
            p.params['id'] = params.id
        }
        if (params?.eventCode) {
            p.params['eventCode'] = params.eventCode
        }
        if (params?.from) {
            p.params['from'] = params.from
        }
        if (params?.to) {
            p.params['to'] = params.to
        }
        if (params?.value) {
            p.params['value'] = params.value
        }
        return p
    },
};
export default MonitorImuServerApi;

