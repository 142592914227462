// ** Axios Imports
import axios from 'axios'

import $ from 'jquery'; 

// ** APIs
import LicensingServerApi from '@src/services/LicensingServerApi'

const SecurityUtils = {
    getCsrf: function() {
        return $("meta[name='nbs-csrf-token']").attr('content')
    },
    updateCsrf: async function() {
        const response = await axios(LicensingServerApi.generateRootAxiosParams('get', '/CSRFToken'))
        const data = response.data
        const token = data?.csrftoken
        if (token) {
            $("meta[name='nbs-csrf-token']").attr('content', token)
        }
    },

};
export default SecurityUtils;

