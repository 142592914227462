// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** APIs
import MonitorImuServerApi from '@src/services/MonitorImuServerApi'
import LicensingServerApi from '@src/services/LicensingServerApi'

//const https = require('https');

// ** App Config
//import appConfig from '@configs/appConfig'


export const isMonitorImuServerRunning = createAsyncThunk('appImu/up', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('get', '/', params))
  return {
    params,
    data: response.data
  }
})

export const getClients = createAsyncThunk('appImu/getClients', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/clients', params))
  return {
    params,
    data: response.data
  }
})

export const getProjects = createAsyncThunk('appImu/getProjects', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/projects', params))
  return {
    params,
    data: response.data
  }
})

export const getImuDetails = createAsyncThunk('appImu/getImuDetails', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('get', '/imu', params))
  return {
    params,
    data: response.data
  }
})

export const getImuSystemUsage = createAsyncThunk('appImu/getImuSystemUsage', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('get', '/imu/system-usage', params))
  return {
    params,
    data: response.data
  }
})

export const getImuPing = createAsyncThunk('appImu/getImuPing', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('get', '/imu/ping', params))
  return {
    params,
    data: response.data
  }
})

export const getAll = createAsyncThunk('appImu/getAll', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('get', '/imu/all', params))
  let d = [...response.data]
  d.map(el => {
    el.handleEditLabel = params.handleEditLabel
    el.handleEditProject = params.handleEditProject
    el.handleEditLocalization = params.handleEditLocalization
    el.clientId = params.clientId
  })
  return {
    params,
    data: d,
    allData: d,
    totalPages: d.length
  }
})

export const getLogsData = createAsyncThunk('appImu/getLogsData', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('get', '/imu/logs', params))
  //console.log(response.data)
  let d = [...response.data]
  d.map(el => {
    el.clientId = params.clientId
  })
  return {
    params,
    data: d,
    allData: d,
    totalPages: d.length
  }
})

export const getEventsData = createAsyncThunk('appImu/getEventsData', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('get', '/imu/events', params))
  let d = [...response.data]
  d.map(el => {
    el.clientId = params.clientId
  })
  return {
    params,
    data: d,
    allData: d,
    totalPages: d.length
  }
})

export const rebootImu = createAsyncThunk('appImu/reboot', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('post', '/imu/reboot', params))
  return {
    params,
    data: response.data
  }
})

export const editLabel = createAsyncThunk('appImu/editLabel', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('post', '/imu/label', params))
  return {
    params,
    data: response.data
  }
})

export const editLocalization = createAsyncThunk('appImu/editLocalization', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('post', '/imu/localization', params))
  return {
    params,
    data: response.data
  }
})

export const editProject = createAsyncThunk('appImu/editProject', async params => {
  const response = await axios(MonitorImuServerApi.generateAxiosParams('post', '/imu/project', params))
  return {
    params,
    data: response.data
  }
})

export const getClient = createAsyncThunk('appImu/getClient', async params => {
  const response = await axios(LicensingServerApi.generateAxiosParams('get', '/client', params))
  return {
    params,
    data: response.data
  }
})

export const appImuListSlice = createSlice({
  name: 'appImuList',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export const appImuLogsSlice = createSlice({
  name: 'appImuLogs',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getLogsData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export const appImuEventsSlice = createSlice({
  name: 'appImuEvents',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEventsData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export const imuList = appImuListSlice.reducer
export const imuLogs = appImuLogsSlice.reducer
export const imuEvents = appImuEventsSlice.reducer
